import { Route, UrlSegment, UrlSegmentGroup } from '@angular/router';

export function BrandUrlMatcher(
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route,
) {
  const paramName: string = 'url';
  const regex: RegExp = /^(([\w-\/]+))(\/[?].*)?$/;

  const parts = regex;
  const posParams: {
    [key: string]: UrlSegment;
  } = {};
  const consumed: UrlSegment[] = [];

  let currentIndex = 0;

  let url = '';
  const param = new UrlSegment(url, {});

  if (segments[0] && segments[0].path !== 'brands') {
    return null;
  }

  // url += segments[0] ? segments[0].path : '';

  for (let i = currentIndex; i < segments.length; ++i) {
    if (currentIndex >= segments.length || segments.length !== 2) {
      return null;
    }
    const current = segments[currentIndex];

    const part = parts;
    if (currentIndex === 0 && current.path !== 'brands') {
      return null;
    }
    // if (currentIndex !== 0 && (!part.test(current.path) || segments.length >= 2)) {
    //   return null;
    // }

    url += '/' + current.path;
    param.path = url;
    posParams[paramName] = param;
    consumed.push(current);
    currentIndex++;
  }

  if (
    route?.pathMatch === 'full' &&
    (segmentGroup?.hasChildren() || currentIndex < segments.length)
  ) {
    return null;
  }

  return { consumed, posParams };
}
