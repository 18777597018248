import { UrlSegment, UrlSegmentGroup, Route } from '@angular/router';

export function FamilyUrlMatcher(
  segments: UrlSegment[],
  segmentGroup: UrlSegmentGroup,
  route: Route,
) {
  const paramName: string = 'famId';
  const regex: RegExp = /^(([\w-]+)-(FAM\d+))(\/[?].*)?$/;
  const parts = regex;
  const posParams: {
    [key: string]: UrlSegment;
  } = {};
  const consumed: UrlSegment[] = [];

  const currentIndex = 0;

  const current = segments[currentIndex];

  const part = parts;
  if (!current || !part.test(current.path)) {
    return null;
  }
  const famId = current.path.slice(
    current.path.lastIndexOf('-') + 1,
    current.path.length,
  );

  posParams[paramName] = new UrlSegment(famId, {});
  consumed.push(current);

  if (
    route?.pathMatch === 'full' &&
    (segmentGroup?.hasChildren() || currentIndex < segments?.length)
  ) {
    return null;
  }

  return { consumed, posParams };
}
